import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { CONFIG } from "../config/config";
import NftCollection from "../components/nftCollection";
import SortBar from "../components/sortBar";
import toast from "react-hot-toast";
import AssetCollection from "../components/assetCollection";
import {
  GetMaxDitos,
  GetDitoIDs,
  GetQueryAddressArray,
} from "../collections/ditos";

function Collection() {
  const [fetchingBalance, setSetchingBalance] = useState(false);

  //   const [booCollectionReady, setBooCollectionReady] = useState(false);
  //   const [gladiatorCollectionReady, setGladiatorCollectionReady] = useState(false);

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const [booPFPTokensCount, setBooPFPTokensCount] = useState(0);
  const [booPFPCollectionKey, setBooPFPCollectionKey] =
    useState("booPFPCollection");
  const [booPFPCollection, setBooPFPCollection] = useState([]);
  let boopfps = [];

  const [booTokensCount, setBooTokensCount] = useState(0);
  const [booCollection, setBooCollection] = useState(false);
  const [booCollectionKey, setBooCollectionKey] = useState("booCollection");
  const [booThingsReady, setBooThingsReady] = useState(false);
  let boothings = [];
  let currentBoo = 0;

  const loadingToast = toast;

  /////
  /////
  ///// BOO THINGS
  /////
  /////
  const fetchCollectionCount = async () => {
    if (fetchingBalance) {
      console.log("Already fetching collection");
      return;
    }

    setSetchingBalance(true);

    try {
      blockchain.smartContract.methods
        .balanceOf(blockchain.account)
        .call()
        .then((receipt) => {
          setBooTokensCount(receipt);
          setSetchingBalance(false);
        });
    } catch (error) {
      console.log(error);
      setSetchingBalance(false);
    }
  };

  //
  //
  //
  const lazyFetchBooCollection = async () => {
    if (currentBoo >= parseInt(booTokensCount)) {
      setBooCollection(boothings);
      setBooThingsReady(true);
      setBooCollectionKey("booCollectionReady");
      loadingToast.dismiss();
      toast.success("All Ghosts Collected", { duration: 4000 });
      return;
    }
    // setBooThingsReady(false);
    setBooCollection(boothings);
    setBooCollectionKey("booCollectionfetching" + currentBoo);

    loadingToast.loading(
      "Hunting " + booTokensCount + " Boos (" + currentBoo + ")",
      { id: loadingToast }
    );

    try {
      blockchain.smartContract.methods
        .tokenOfOwnerByIndex(blockchain.account, currentBoo)
        .call()
        .then((receipt) => {
          boothings.push(receipt);

          currentBoo++;
          lazyFetchBooCollection();
        });
    } catch (error) {
      console.log(error);
    }
  };

  ///
  ///
  /// BOO PFP
  ///
  ///
  const fetchBooPFPCount = async () => {
    let maxID = parseInt(CONFIG.TOKENS.BOO_PFP_CURRENT_TOKEN_ID);
    let ids = [];
    let addresses = [];

    for (let i = 1; i <= maxID; i++) {
      ids.push(i);
      addresses.push(blockchain.account);
    }

    try {
      const tokenCount = blockchain.booPFPSmartContract.methods
        .balanceOfBatch(addresses, ids)
        .call()
        .then((receipt) => {
          // console.log("fetchBooPFPCount balanceOf Token", receipt);
          setPFPIds(receipt);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //
  //
  //
  function setPFPIds(balance) {
    let i = 1;
    balance.forEach((e) => {
      if (parseInt(e) > 0) {
        boopfps.push(i);
        setBooPFPCollection(boopfps);
        setBooPFPCollectionKey("booPFPCollection" + i);
      }
      i++;
    });

    setBooPFPCollectionKey("booPFPCollection");

    if (boopfps.length > 0) {
      toast.success("Boo PFPs Collected", { duration: 3000 });
      setBooPFPTokensCount(boopfps.length);
    }
  }

  //
  //
  // DITO THE PUG
  //
  //
  const fetchDitoCount = async () => {
    try {
      const tokenCount = blockchain.openSeaSmartContract.methods
        .balanceOfBatch(GetQueryAddressArray(blockchain.account), GetDitoIDs())
        .call()
        .then((receipt) => {
          console.log("fetchDitoCount balanceOf Token", receipt);
          // setPFPIds(receipt);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Called for every wallet change
  useEffect(() => {
    if (!blockchain.account) {
      return; // Waiting for wallet to connect
    }

    fetchCollectionCount();
    fetchBooPFPCount();
    // fetchDitoCount();
    // console.log("Conta conectou ou mudou", blockchain.account);
  }, [blockchain.account]);

  useEffect(() => {
    if (!blockchain.account || parseInt(booTokensCount) === 0) {
      return;
    }

    lazyFetchBooCollection();
    toast.loading("Hunting " + booTokensCount + " Boos", {
      id: loadingToast,
    });
  }, [booTokensCount]);

  const handleConnect = () => {
    dispatch(connect(CONFIG));
  };

  if (blockchain.account == null) {
    return (
      <>
        <h4>Please, Connect your Wallet</h4>
      </>
    );
  }

  // RETURN
  return (
    <>
      {booTokensCount > 0 ? (
        <AssetCollection id="wallpapers" title="Wallpapers" />
      ) : null}

      {booPFPCollection ? (
        <NftCollection
          key={booPFPCollectionKey}
          type="booPFP"
          collection={booPFPCollection}
          id="booPFPCollection"
          title={"Boo PFPs (" + booPFPTokensCount + ")"}
        />
      ) : null}
      <div>
        {booCollection ? (
          <>
            <NftCollection
              key={booCollectionKey}
              type="boo"
              collection={booCollection}
              id="booCollection"
              title={"Boo Things (" + booTokensCount + ")"}
            />
          </>
        ) : null}
        {booThingsReady ? <SortBar /> : null}
      </div>
    </>
  );
}

export default Collection;
