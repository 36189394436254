import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { CONFIG } from "../config/config";

// class Connector extends Component {
//   render() {
//     return <></>;
//   }
// }
// export default Connector;

export const walletAddress = null;

function ConnectButton() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const handleConnect = () => {
    dispatch(connect(CONFIG));
  };

  return (
    <div className="walletBar">
      {blockchain.account === null ? (
        <button className="btnConnect" onClick={handleConnect}>
          Connect Wallet
        </button>
      ) : (
        <>
          <div className="icon"></div>
          <div className="walletAddress">
            {blockchain.account.substring(0, 8)}...
            {blockchain.account.substring(
              blockchain.account.length - 5,
              blockchain.account.length
            )}
          </div>
        </>
      )}
    </div>
  );
}
export default ConnectButton;
