// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import { fetchData } from "../data/dataActions";
import toast from "react-hot-toast";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = (CONFIG) => {
  return async (dispatch) => {
    dispatch(connectRequest());

    const abiBooResponse = await fetch("/config/abi/abi-boo.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const abiBoo = await abiBooResponse.json();

    const abiBooPFPResponse = await fetch("/config/abi/abi-boo-pfp.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const abiBooPFP = await abiBooPFPResponse.json();

    const abiOpenSeaResponse = await fetch(
      "/config/abi/abi-opensea-polygon.json",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const abiOpenSea = await abiOpenSeaResponse.json();
    // const abiGladiator = await abiBooResponse.json();

    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);

      let web3 = new Web3(ethereum);

      try {
        console.log("try");
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({ method: "net_version" });

        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObjBoo = new Web3EthContract(
            abiBoo,
            CONFIG.CONTRACTS.BOO_THINGS
          );

          const SmartContractObjBooPFP = new Web3EthContract(
            abiBooPFP,
            CONFIG.CONTRACTS.BOO_PFP
          );

          const SmartContractOpenSea = new Web3EthContract(
            abiBooPFP,
            CONFIG.CONTRACTS.OPENSEA_POLYGON
          );

          // const SmartContractObjGlad = new Web3EthContract(
          //   abiGladiator,
          //   CONFIG.GLADIATOR_ADDRESS
          // );

          console.log(
            "Endereço do contrato",
            CONFIG.CONTRACTS.BOO_PFP,
            CONFIG.CONTRACTS.BOO_THINGS
          );

          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObjBoo,
              booPFPSmartContract: SmartContractObjBooPFP,
              openSeaSmartContract: SmartContractOpenSea,
              web3: web3,
            })
          );

          console.log(accounts);

          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });

          // Add listeners end
        } else {
          console.log(`Change network to Polygon.`);
          toast.error(`Change network to Polygon.`, {
            style: {
              background: "#780020",
              color: "#fff",
            },
          });
          // dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        console.log("Something went wrong", err.message);
        // console.log(err.message);
        // dispatch(connectFailed("Oops..."));
        toast.error(err.message);
      }
    } else {
      console.log("Compatible with Metamask Only");
      // dispatch(connectFailed("Compatible with Metamask Only"));
      toast.error("Compatible with Metamask Only", {
        style: {
          color: "#ff3b5f",
        },
      });
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
