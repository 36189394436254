import React, { useState } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import ConnectButton, { walletAddress } from "../components/connector";
import { useSelector } from "react-redux";

function Navbar() {
  const blockchain = useSelector((state) => state.blockchain);
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  const renderRestrictedMenu = () => {
    return blockchain.account !== null ? (
      <ul>
        <li>
          <Link to="/" onClick={handleClick}>
            Collection
          </Link>
        </li>
        <li>
          <Link to="/wallpapers" onClick={handleClick}>
            Wallpapers
          </Link>
        </li>
      </ul>
    ) : null;
  };

  return (
    <header>
      <nav className="main">
        <ul>
          <li className="siteLogo">
            <Link to="/">
              <img src="/images/logo/boo-things.svg" />
            </Link>
          </li>
          <li className="menu">{renderRestrictedMenu()}</li>
          <li className="wallet">
            <ConnectButton />
          </li>
        </ul>
      </nav>

      {blockchain.account !== null ? (
        <nav className="mobile">
          <button className="open" onClick={handleClick}></button>
          <div className={isActive ? "menu-lightbox" : "hidden"}></div>
          <div id="wrapper-menu" className={isActive ? "visible" : "hidden"}>
            <ul>
              <li className="siteLogo">
                <Link to="/">
                  <img src="/images/logo/boo-things.svg" />
                </Link>
              </li>
              <li className="menu">{renderRestrictedMenu()}</li>
              <li className="closeButton">
                <button onClick={handleClick}></button>
              </li>
            </ul>
          </div>
        </nav>
      ) : null}
    </header>
  );
}

export default Navbar;
