import React, { Component } from "react";

class AssetCard extends Component {
  renderCard() {
    const { id } = this.props;

    return (
      <>
        <li key={id} className="boo-card wallpaper">
          {/* <div className="boo-number">Boo PFP #{id}</div> */}

          <div className="image-wrapper">
            <a
              href={"https://ditothepug.com/wp-content/wallpapers/" + id}
              alt={"Download the full size wallpaper"}
              title={"Download the Full size wallpaper"}
              target="_blank"
              download={id}
            >
              <div className="tool download"></div>
            </a>
            <img
              src={"https://ditothepug.com/wp-content/wallpapers/thumb/" + id}
            />
          </div>
        </li>
      </>
    );
  }

  render() {
    return <>{this.renderCard()}</>;
  }
}

export default AssetCard;
