import React, { Component } from "react";
import NFTCard from "./nftCard";
import NFTCardPFP from "./nftCardPFP";
import GetRank, { GetPower } from "../collections/ranking";

class NftCollection extends Component {
  state = {
    type: this.props.type,
    collection: this.props.collection,
    id: this.props.id,
    title: this.props.title,
  };

  render() {
    const { type, id, collection, title } = this.state;

    // console.log("<NftCollection> render collection", collection);

    if (!collection || collection.length === 0) {
      console.log("No collection given");
      return <></>;
    }

    return (
      <>
        <h2>{title}</h2>
        {type === "booPFP" ? (
          <div className="collection" id={id}>
            {collection.map((i) => (
              <NFTCardPFP key={i} id={i} />
            ))}
          </div>
        ) : (
          <div className="collection" id={id}>
            {collection.map((i) => (
              <NFTCard
                key={i}
                rank={GetRank(i)}
                power={GetPower(i)}
                type={type}
                id={i}
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

export default NftCollection;
